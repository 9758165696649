export const TOKEN = 'token'
export const USER_INFO = 'user_info'
export const COMPANY_INFO = 'company_info'
export const TAGS_VIEW = 'tagsView'
export const ROLE_INFO = {
  1: {
    name: '超管',
    permission: {
      menus: ['home', 'usermanage', '4smanage'],
      points: ['addShop', 'editShop', 'deleteShop', 'addShopManager']
    }
  },
  2: {
    name: '管理员',
    permission: {
      menus: [
        'home',
        'usermanage',
        '4smanage',
        'accountmanage',
        'discountmanage',
        'searchhistory'
      ]
    }
  },
  4: {
    name: '普通管理员',
    permission: {
      menus: ['home', 'usermanage']
    }
  },
  3: {
    name: '采购客户',
    permission: {
      menus: ['home']
    }
  }
}
