import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在这个位置需要统一的去注入token
    if (store.getters.token) {
      // 如果token存在 注入token
      config.headers.token = `${store.getters.token}`
    }
    return config // 必须返回配置
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { error_code: errorCode, msg } = response.data
    //   要根据success的成功与否决定下面的操作
    if (+errorCode === 0) {
      return response.data
    } else if (+errorCode === 8001 || +errorCode === 4008) {
      // token超时
      store.dispatch('user/logout')
    }
    // 业务错误
    ElMessage.error(msg + '') // 提示错误消息
    return Promise.reject(new Error(msg + ''))
  },
  (error) => {
    // TODO: 将来处理 token 超时问题
    ElMessage.error(error.message) // 提示错误信息
    return Promise.reject(error)
  }
)

export default service
