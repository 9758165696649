import router from './router'
import store from './store'
import { ROLE_INFO } from '@/constant'

let isFirstEnter = true

// 白名单
const whiteList = ['/login']
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  if (store.getters.token && store.getters.userInfo) {
    if (to.path === '/login') {
      next('/')
    } else {
      if (isFirstEnter) {
        const { permission } = ROLE_INFO[store.getters.userInfo.authority]
        // 4s 店铺前缀
        const prefix = store.getters.companyInfo.prefix || ''
        // 处理用户权限，筛选出需要添加的路由
        const filterRoutes = await store.dispatch('permission/filterRoutes', {
          menus: permission.menus,
          prefix
        })
        // 利用 addRoute 循环添加
        filterRoutes.forEach((item) => {
          router.addRoute(item)
        })
        // console.log(filterRoutes)
        isFirstEnter = false
        // 添加完动态路由之后，需要在进行一次主动跳转
        // return to.path.includes(prefix)
        //   ? next(to.path)
        //   : next(`/${prefix}${to.path}`)
        return next(to.path)
      }
    }
    document.title = to.meta.title
      ? `BMW配件管理系统-${to.meta.title}`
      : 'BMW配件管理系统'
    next()
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
