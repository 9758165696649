import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import layout from '@/layout'
import { ROLE_INFO } from '@/constant'
/**
 * 私有路由表
 */
export const privateRoutes = [
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          title: '首页',
          icon: 'el-icon-s-home'
        }
      },
      {
        path: '/usermanage',
        name: 'usermanage',
        component: () => import('@/views/user-manage/index'),
        meta: {
          title: '用户管理',
          icon: 'el-icon-s-custom'
        }
      },
      {
        path: '/4smanage',
        name: '4smanage',
        component: () => import('@/views/4s-manage/index'),
        meta: {
          title: '系统设置',
          icon: 'shezhi'
        }
      },
      {
        path: '/accountmanage',
        name: 'accountmanage',
        component: () => import('@/views/account-manage/index'),
        meta: {
          title: '账号管理',
          icon: 'role'
        }
      },
      {
        path: '/discountmanage',
        name: 'discountmanage',
        component: () => import('@/views/discount-manage/index'),
        meta: {
          title: '折扣管理',
          icon: 'zhekou'
        }
      },
      {
        path: '/searchhistory',
        name: 'searchhistory',
        component: () => import('@/views/search-history/index'),
        meta: {
          title: '查询记录',
          icon: 'search'
        }
      }
    ]
  }
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  // {
  //   path: '/',
  //   // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
  //   component: layout,
  //   children: [
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404')
  }
  //   ]
  // }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (store.getters.userInfo) {
    const menus = ROLE_INFO[store.getters.userInfo.authority].permission.menus
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  // base: '/',
  history: createWebHistory(),
  routes: publicRoutes
})

export default router
