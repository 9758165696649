// 专门处理权限路由的模块
import { publicRoutes, privateRoutes } from '@/router'

export default {
  namespaced: true,
  state: {
    // 路由表：初始拥有静态路由权限
    routes: publicRoutes
  },
  mutations: {
    /**
     * 增加路由
     */
    setRoutes(state, newRoutes) {
      // 永远在静态路由的基础上增加新路由
      state.routes = [...publicRoutes, ...newRoutes]
    }
  },
  actions: {
    /**
     * 根据权限筛选路由
     */
    filterRoutes(context, { menus, prefix }) {
      const routes = []
      routes.push({
        path: '/',
        redirect: prefix ? `/${prefix}/home` : '/home'
      })
      privateRoutes.forEach((item) => {
        // 路由权限匹配
        if (item.children) {
          const children = []
          menus.forEach((key) => {
            // 权限名 与 路由的 name 匹配
            children.push(
              ...item.children
                .filter((item) => item.name === key)
                .map((item) => ({
                  ...item,
                  path: prefix ? `/${prefix}${item.path}` : item.path
                }))
            )
          })
          routes.push({
            ...item,
            // redirect: item.path === '/' ? `/${prefix}/home` : '',
            path: prefix ? `/${prefix}${item.path}` : item.path,
            children
          })
        }
      })
      // 最后添加 不匹配路由进入 404
      routes.push({
        path: '/:catchAll(.*)',
        redirect: '/404'
      })
      context.commit('setRoutes', routes)
      return routes
    }
  }
}
