<template>
  <div class="">
    <div class="logo-container">
      <el-avatar
        v-if="$store.getters.companyInfo.img"
        :src="$store.getters.companyInfo.img"
      />
      <h1
        class="logo-title"
        v-if="$store.getters.sidebarOpened"
        v-html="formatShopname($store.getters.companyInfo.name)"
      ></h1>
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'

const formatShopname = (name) => {
  if (!name) return name
  const res = name.replace(
    /[（(](.*?)[）)]/g,
    '<span style="font-size: 14px;">（$1）</span>'
  )
  console.log(res)
  return res
}
</script>

<style lang="scss" scoped>
.logo-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #001529;
  .logo-title {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 20px;
    white-space: nowrap;
  }
}
</style>
