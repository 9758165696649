import variables from '@/styles/variables.scss'
const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  companyInfo: (state) => state.user.companyInfo,
  cssVar: (state) => variables,
  sidebarOpened: (state) => state.app.sidebarOpened,
  tagsViewList: (state) => state.app.tagsViewList,
  hasInitializeRoute: (state) => state.permission.hasInitialize
}
export default getters
